/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": process.env.NEXT_PUBLIC_AWS_PROJECT_REGION,
    "aws_cognito_identity_pool_id": process.env.NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.NEXT_PUBLIC_AWS_COGNITO_REGION,
    "aws_user_pools_id": process.env.NEXT_PUBLIC_AWS_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.NEXT_PUBLIC_AWS_WEB_CLIENT_ID,
    "oauth": {},
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://ivxs0l6no6.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
